<template>
    <el-main>
        <div class="nav">
            <el-row class="container" type="flex" justify="center">
                <el-col :span="6">
                    <img class="right" src="@/assets/images/test/left.png">
                </el-col>
                <el-col :span="6" class="nav-sub">
                        <div class="nav-sub-title">在飞书，享高效</div>
                        <div class="nav-sub-button">
                            <el-button type="primary" round>主要按钮</el-button>
                            <el-button round>圆角按钮</el-button>
                        </div>
                </el-col>
                <el-col :span="6">
                    <img src="@/assets/images/test/right.png">
                </el-col>
            </el-row>
        </div>

        <div class="container">
            <el-row :gutter="20" class="shop">
                <el-col :span="24">
                    <div class="shop-category">
                        <el-radio-group v-model="radio" size="mini">
                            <el-radio-button label="上海"></el-radio-button>
                            <el-radio-button label="北京"></el-radio-button>
                            <el-radio-button label="广州"></el-radio-button>
                            <el-radio-button label="深圳"></el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="4" :lg="6" :xl="6">
                    <div class="shop-item">
                        <el-image
                            class="shop-item-thumb"
                            :src="url"
                            fit="fill"></el-image>
                        <div class="shop-item-title">深入浅出剖析Python的全局解释锁GIL</div>
                        <div class="shop-item-info">
                            <div class="shop-item-user">
                                <i class="el-icon-user-solid"></i>RG
                            </div>
                            <div class="shop-item-price">
                                <small>￥</small>0
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </el-main>
</template>

<script>
export default {
    data() {
        return {
            radio: '上海',
            url: require("@/assets/images/test/edit.png")
        }
    }
}
</script>

<style lang="less" scoped>
.el-main{
    padding: 0;
}
.nav{
    background: rgba(240,244,255,.5);
    padding-top: 50px;
    .container{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-pack: center;
        justify-content: center;
        height: auto;
    }
}
.nav-sub{
    height: 100%;
    text-align: center;
    .nav-sub-title{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
        white-space: pre-wrap;
        color: #1f2329
    }
    .nav-sub-button{
        margin-top: 30px;
    }
}

.text-overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}   

/* Shop Item */
.shop{
    margin: 30px 0;
    .shop-category{
        margin-bottom: 50px;
    }
}
.shop-item{
    overflow: hidden;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    color: #303133;
    cursor: pointer;
    border-radius: 4px;
    -webkit-transition: .3s;
    transition: .3s;
    .shop-item-thumb{
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s
    }
    &:hover{
        .shop-item-thumb{
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }
        -webkit-box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
        box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
    }
    .shop-item-title{
        padding: 10px;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
    }
    .shop-item-info{
        padding: 0 10px 10px;
        overflow: auto;
        .shop-item-user{
            float: left;
            line-height: 24px;
            max-width: 30%;
            .text-overflow
        }
        .shop-item-price{
            float: right;
            color: #F20D0D;
            background: rgba(255,64,64,.1);
            border-radius: 12px;
            padding: 4px 12px;
            max-width: 30%;
            .text-overflow
        }
    }
}
</style>